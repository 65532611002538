<template>
  <div class="potential">
    <rxNavBar title='加班调休'></rxNavBar>
    <van-tabs v-model="active"
              line-width="20"
              line-height="5"
              title-active-color="rgb(255,81,45)"
              sticky
              offset-top="1.33rem" @scroll="scroll"
    >
      <van-tab title="详情">
        <div class="basicInfo">
          <div class="panel">
            <div :class="'state top-title top-title-'+detailValid.approvalStatus">{{detailValid.approvalStatusdescription}}</div>
            <van-row class="info">
              <van-col span="12">
                <p>申请人：<span>{{detailValid.addstaffName}}</span></p>
              </van-col>
              <van-col span="12">
                <p>审批人：<span>{{detailValid.approvalStaff}}</span></p>
              </van-col>
              <van-col span="12">
                <p>类型：<span>{{detailValid.dataTypeDescription}}</span></p>
              </van-col>
              <van-col span="12">
                <p>申请时间：<span>{{detailValid.addTimeStr}}</span></p>
              </van-col>
              <van-col span="12" v-if="detailValid.dataType == '1'">
                <p>加班时间：<span>{{detailValid.expectedTimeStr}}</span></p>
              </van-col>
              <van-col span="12" v-if="detailValid.dataType == '2'">
                <p>调休时间：<span>{{detailValid.expectedTimeStr}}</span></p>
              </van-col>
              <van-col span="12">
                <p>审批节点：<span>{{detailValid.approvalStatusdescription}}</span></p>
              </van-col>
              <van-col span="24">
                <p>部门：<span>{{detailValid.departmentName}}</span></p>
              </van-col>
              <van-col span="24">
                <p>事由：<span>{{detailValid.remarks}}</span></p>
              </van-col>
            </van-row>
          </div>

          <p class="subtitle" v-if="enterType==='approve'">审批</p>
          <div v-if="enterType==='approve'">
            <div class="panel radio_body withMargin">
              <p style="marginBottom:15px">
                <span :class="isChoose ? 'dot is_dot' : 'dot'"></span>
                审核结果
              </p>
              <div class="choose">
                <div :class="isPass == 1?'choose_btn pass': 'choose_btn'" @click="multipleChoice(1)">通过</div>
                <div class="line"></div>
                <div :class="isPass == 0?'choose_btn reject': 'choose_btn'" @click="multipleChoice(0)">驳回</div>
              </div>
            </div>
            <div id="cleanId" v-show="refuseTypeShow" class="part-inputpart-row" @click="refuseReasonShow = !refuseReasonShow">
              <span :class="!selectRefuseReason.dictionaryTitle? 'notdot' : 'isdot'" > </span>
              <span class="part-inputpart-row-header">原因</span>
              <span class="divide">|</span>
              <span class="part-inputpart-row-graytext" :class="selectRefuseReason.dictionaryTitle ? 'part-inputpart-row-normaltext':'' ">{{selectRefuseReason.dictionaryTitle}}</span>
              <img class="part-inputpart-row-right" :class=" refuseReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
            </div>

            <div class="panel">
              <p class="yijian">{{isPass == 1?'审批意见（必填）':'审批意见'}}</p>
              <div class="text_body">
                <van-field v-model="selectRefuseReason.approvalOpinion"  type="textarea" class="mes_body" />
              </div>
            </div>
            <div class="sub_btn">
              <van-button  :class="checkApprovalResult() ?'saveButton_Enable':'saveButton_Disable'" :disabled="!checkApprovalResult()" size="large" @click="save">保存
              </van-button>
              <van-loading v-if="enable" type="spinner"  size="40px" class="upload-loading"/>
            </div>
          </div>
          <van-popup v-model="refuseReasonShow" position="bottom">
            <van-picker
                show-toolbar
                :columns="refuseReasonList"
                @cancel="refuseReasonShow = false"
                :default-index="selectRefuseReason.refuseReasonIndex"
                value-key="dictionaryTitle"
                @confirm="selectRefuseReasonOption"
            />
          </van-popup>
        </div>
      </van-tab>
      <van-tab title="审批">
        <div class="approve" v-if="approvalHistroyList.length > 0">
          <div class="time_line">
            <div class="item" v-for="(item,index) in approvalHistroyList" :key="index" >
              <div class="left">
                <div :class="{line:true ,line_top:true,first_top:index===0}"></div>
                <div class="point"></div>
                <div class="line line_bottom" v-if="index != approvalHistroyList.length - 1"></div>
              </div>
              <div class="right">
                <div class="approve_panel">
                  <p class="total_color">
                    <span style="display: inline-block;">{{item.approvalResultStr}}</span>
                    <span class="floatR small_font">{{item.approvalTime}}</span>
                  </p>
                  <p class="total_color">
                    <span style="display: inline-block;">{{item.remarks}}</span>
                    <span class="floatR small_font">{{item.staffName}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
            <common-empty></common-empty>
        </div>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {Button, Col, DropdownItem, DropdownMenu, Field, Picker, Popup, Row, Tab, Tabs,Loading} from "vant";
import {getStaffId,responseUtil} from "../../../libs/rongxunUtil";
import { queryBaseData,approvalDataMob,queryApprovalDataMob} from "../../../getData/getData";

export default {
  name: "staffEntryDetail",
  components:{
    rxNavBar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Row.name]: Row,
    [Col.name]: Col,
    [Button.name]: Button,
    [DropdownMenu.name]:DropdownMenu,
    [DropdownItem.name]:DropdownItem,
    [Picker.name]: Picker,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Loading.name]:Loading,
  },
  data(){
    return{
      offsetTop:'',
      navTitle:'',
      enterType:'',
      active: 0,
      detailValid:{},
      approvalHistroyList:[],

      isChoose:true,  //是否选择审核结果
      isPass:1,  //是否选择通过
      refuseTypeShow:false,
      refuseReasonShow:false,  //拒绝原因弹框
      selectRefuseReason:{},
      refuseReasonList:[],

      detailFormdata: {},
      enable:false
    }
  },
  activated() {
    this.active = 0
    this.isPass = 1
    this.refuseTypeShow = false
    this.enterType = this.$route.query.enterType

    console.log("routeDate:",this.$route.query)


    this.detailValid = this.$route.query
    this.selectRefuseReason = {}
    this.initStaffEntryDetail()
    this.initDictionaryData()
  },
  mounted() {
    this.active = 0
    this.isPass = 1
    this.detailValid = this.$route.query
    this.selectRefuseReason = {}
    
    this.initStaffEntryDetail()
    this.initDictionaryData()
  },
  methods:{
    initStaffEntryDetail(){
      let that = this
      let initData = {}
      initData.id = this.$route.query.id
       queryApprovalDataMob(initData).then(function (response){
        that.detailFormdata = response.data.data.employTransferApproval
        that.approvalHistroyList = response.data.data.approvalList
        console.log('dasdsadsa',that.approvalHistroyList)
      })
   
    },
    //选择审批意见
    multipleChoice(isPass) {
      if(isPass == 1){
        this.refuseTypeShow = false
      }else {
        this.refuseTypeShow = true
      }
      //审核结果
      this.isChoose = true;
      //this.grade= 0;
      this.isPass = isPass
    },
    //选择拒绝原因
    selectRefuseReasonOption(item,index){
      this.selectRefuseReason.refuseReason_id = item.id
      this.selectRefuseReason.refuseReasonIndex = index
      this.selectRefuseReason.dictionaryTitle = item.dictionaryTitle

      this.refuseReasonShow = false
    },
    save(){
      if(!this.checkApprovalResult()){
        responseUtil.alertMsg(this,'驳回请选择驳回原因')
        return
      }
      if(this.enable){
            return
        }
        this.enable = true

        var that = this
        const params = {
            staff_id: getStaffId(),
            approval_id: this.detailValid.approval_id,
            id: this.detailValid.id,
            approvaType: this.isPass===1 ? '0' : '1',
            approvalOpinion: this.selectRefuseReason.approvalOpinion,
            refuseReason_id: this.isPass===1 ? '' : this.selectRefuseReason.refuseReason_id
        }
        console.log(params,"params===")
        approvalDataMob(params).then(res => {
            responseUtil.dealResponse(that, res, () => {
                responseUtil.alertMsg(that, res.data.msg)
                that.$router.go(-1)
                that.enable = false
            })
        })

    },
    checkApprovalResult(){
      if(this.isPass===1 && (!this.selectRefuseReason.approvalOpinion || this.selectRefuseReason.approvalOpinion=='')){
        return false
      }
      if(this.isPass===0){  //驳回
        if(this.selectRefuseReason.refuseReason_id==undefined || this.selectRefuseReason.refuseReason_id==''){
          return false
        }
      }
      return true
    },
    // 获取字典数据
    initDictionaryData: function () {
      let that = this
      let initData = {}
      initData.user_id = getStaffId()
      initData.dbName = ["workovertimecompensation"]
      queryBaseData(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.refuseReasonList = response.data.data.workovertimecompensation;
        })
      })
    },
    // 滚动时获取nav高度并赋值
    scroll() {
      if (this.$refs.navBar) {
        this.offsetTop = this.$refs.navBar.clientHeight
      }

    },
  }
}
</script>

<style scoped lang="less">
*{
  margin: 0px;
}
.potential {
  width: 100%;
  background-color: #f8f8f8;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;
}
//小标题
.subtitle {
  padding: 5px 0px;
  color: rgb(244, 99, 76);
  margin-top: 15px;
  font-weight: 700;
  font-size: 12px;
}
//详情
.basicInfo{
  padding: 0 0.4rem;
  margin-top: 0.4rem;
}
//基本信息
.panel {
  width: 345px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  position: relative;
  font-size: 14px;
  text-align: left;
  overflow: hidden;


  &.withMargin {
    margin: 0px 0 16px;
  }

  &>p {
    padding: 15px 15px 0px;
    font-weight: bolder;
    /*color: #252525;*/

  }

  &>p>span {
    font-weight: normal;
    color: rgb(190, 190, 190);
  }

  .state {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    font-weight: bold;
    height: 32px;
    line-height: 32px;
    //width: 60px;
    display: block;

    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color: rgb(255, 255, 255);
    text-align: center;
    transform: scale(0.5);
    transform-origin: 0% 0%;
    font-family: sans-serif;
    padding: 0 10px;
  }
  .top-title-0,.top-title-1,.top-title-2,.top-title-3{
  background: linear-gradient(to right, #63cb96, #30d0ac);
}
.top-title-1,.top-title-2{
  background: linear-gradient(to right, #ffbe72, #ff6c41);
}
.top-title-3,.top-title-4{
  background: linear-gradient(to right, #bfcbd9, #a8b9cc);
}

.top-title-5{
  background: linear-gradient(to right, #cc004b, #660026);
}
.top-title-6{
  background: linear-gradient(to right,  #7cc7ff, #3596fd);
}

  .title {
    width: 315px;
    padding: 0 15px;
    font-weight: bolder;
    color: rgb(0, 0, 0);
    font-size: 16px;
    padding-top: 20px;

    span {
      font-size: 12px;
      font-weight: normal;
      color: rgba(153, 153, 153, 1);
    }


  }
  .allprice{
    padding-bottom: 4px;
  }
  /*审核结果,服务人员 审批意见*/
  .shenhe,.fuwu,.yijian{
    font-size: 15px;
  }
  .describe {
    padding-bottom: 15px;
    padding-top: 10px;
    color: rgb(82, 82, 82);
    font-weight: normal;
  }

  .info {
    padding: 15px;
    line-height: 16px;

    &.noPadding {
      padding: 0 15px;
    }

    p {
      margin: 7px 0;
      font-weight: bolder;

      span {
        font-size: 13px;
        font-weight: normal;
        color: rgb(110, 110, 110);
      }
    }

    .floatR {
      float: right;
    }

    .total_color {
      color: rgba(255, 93, 59, 1);
    }
  }

  .text_body {
    padding: 15px;

    .mes_body {
      border-radius: 8px;
      background-color: rgb(250, 250, 250);
    }
  }
}
//审批
// 单选框
.radio_body {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;

  .dot {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgb(136, 136, 136);
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom: 6px;

    &.is_dot {
      background-color: rgb(251, 91, 64);
    }
  }

  .line {
    width: 2px;
    height: 12px;
    background-color: rgb(250, 250, 250);
  }

  .choose {
    margin-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;

    .choose_btn {
      padding: 5px;
      margin: 10px;
      color: rgb(136, 136, 136);
      cursor: pointer;

      &.pass {
        color: rgb(251, 91, 64);
        font-weight: bolder;
      }

      &.reject {
        color: red;
        font-weight: bolder;
      }
    }
  }
}
//选择
.part-inputpart-row {
  width: 345px;
  float: left;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  height: 50px;
  border-radius: 8px;
  margin: 0 5px 15px 0;
  .notdot {
    display: inline-block;
    padding: 3.5px;
    border-radius: 50%;
    background-color: rgb(136, 136, 136);
    vertical-align: middle;
    margin-right: 13px;
    margin-left: 15px;
  }
  .isdot{
    display: inline-block;
    width: 8px;
    height: 6px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 13px;
    background-color: rgb(251,91,64);
    margin-left: 15px;
  }
  .part-inputpart-row-header{
    font-weight: bold;
    font-size: 14px;
    width: 90px;
    color: black;
  }
  .divide {
    font-size: 14px;
    color: #efefef;
    margin:0 7px 0 10px;
  }
  .part-inputpart-row-graytext {
    color: #d9d9d9;
    width: 100%;
    font-size: 14px;
  }
  .part-inputpart-row-normaltext{
    color: black;
  }
  .part-inputpart-row-right-downArrow{
    width: 9px;
    height: 9px;
    margin-right: 15px;
  }
  /*选择三角图标的向上向下样式*/
  .part-inputpart-row-right-upArrow{
    width: 9px;
    height: 9px;
    /*transform: rotateX(180deg);*/
    margin-right: 15px;
  }
}
//保存按钮
.sub_btn {
  margin: 30px 0 70px;
  /*保存按钮不可点击样式*/
  .saveButton_Disable{
    /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
    background-color: rgba(184, 184, 184, 0.2);
    color: white;
    width: 345px;
    height: 50px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1.22rem;
    margin-left: 0;
    text-align: center;
  }
  /*保存按钮可点击样式*/
  .saveButton_Enable{
    background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
    color: white;
    width: 345px;
    height: 50px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 1.22rem;
    text-align: center;
  }
}


// 时间线
.approve {
  margin-top: 20px;
  .time_line {
    .item {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      .left {
        width: 20px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 15px;
        /*margin-right: 5px;*/

        .line {
          width: 1px;
          background-color: rgb(255, 75, 39);
          position: absolute;

          &.line_top {
            top: 0px;
            height: ceil(50%-8px);

            &.first_top {
              background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Safari 5.1 - 6.0 */
              background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Opera 11.1 - 12.0 */
              background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
              /* Firefox 3.6 - 15 */
              background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
            }
          }

          &.line_bottom {
            bottom: 0;
            height: ceil(50%+10px);
          }
        }

        .point {
          position: relative;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          border: 1px solid rgb(255, 75, 39);
          transform: translateY(-50%);
          z-index: 99;
          background-color: rgb(255, 255, 255);

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgb(255, 75, 39);
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }


      }

      .right {
        width: 100%;
        padding-bottom: 20px;

        .approve_panel {
          margin: 0 15px;
          border-radius: 8px;
          background-color: rgb(255, 255, 255);
          padding: 10px;

          p {
            padding-top: 10px;
            font-weight: bolder;

            &:last-of-type {
              padding-bottom: 10px;
            }

            span {
              font-weight: normal;
              color: rgb(82, 82, 82);
            }
           

            .floatR {
              float: right !important;
            }
          }
        }
      }
    }

  }
}
.upload-loading{
        position: fixed;
        z-index: 999;
        margin-top: -1.3rem;
        margin-left: 4.1rem;
    }
</style>